import Call from "./Call";
import {useQuery} from "@tanstack/react-query";
import {getRecentCalls} from "../api/calls";
import {useState} from "react";
import {PaginationAndFilter} from "./PaginationAndFilter";

const CallsContainer = () => {
    const [queryParams, setQueryParams] = useState({
        start: 0,
        pageSize: 10,
        from: null,
        to: null,
        lat: null,
        lng: null
    });

    const goPrevious = () => {
        let previousStart = queryParams.start - queryParams.pageSize;
        previousStart = previousStart < 0 ? 0 : previousStart;
        setQueryParams({...queryParams, start: previousStart, pageSize: queryParams.pageSize});
    }
    const goNext = () => {
        setQueryParams({
            ...queryParams,
            start: queryParams.start + queryParams.pageSize,
            pageSize: queryParams.pageSize
        });
    }
    const setFrom = (date) => setQueryParams({...queryParams, from: date});
    const setTo = (date) => setQueryParams({...queryParams, to: date});
    const setLat = (val) => setQueryParams({...queryParams, lat: val});
    const setLng = (val) => setQueryParams({...queryParams, lng: val});

    const clearDates = () => {
        setQueryParams({...queryParams, from: null, to: null, lat: null, lng: null});
    };

    const {isLoading, isRefetching, isError, data, refetch} = useQuery({
        queryKey: ["get-calls", {
            start: queryParams.start,
            pageSize: queryParams.pageSize,
            from: queryParams.from,
            to: queryParams.to,
            lat: queryParams.lat,
            lng: queryParams.lng
        }],
        queryFn: getRecentCalls,
        refetchInterval: 30000, // refetch every 30 sec,
        refetchIntervalInBackground: true
    });
    const dataToRender = data || {hasNext: false, hasPrevious: false, calls: []};

    return <div className="mx-auto max-w-7xl sm:rounded-lg sm:px-6 lg:px-8">
        <PaginationAndFilter
            clearDates={clearDates}
            fromDate={queryParams.from}
            toDate={queryParams.to}
            setFromDate={setFrom}
            setToDate={setTo}
            lat={queryParams.lat}
            setLat={setLat}
            lng={queryParams.lng}
            setLng={setLng}
            displayRefresh={true}
            isLoading={isLoading || isRefetching}
            isError={isError}
            refreshFn={refetch}
            goPrevious={goPrevious}
            goNext={goNext}
            hasNext={dataToRender.hasNext}
            hasPrevious={dataToRender.hasPrevious}/>
        <div className="flex py-16 flex-col space-y-2 bg-gray-100">
            {dataToRender.calls.map(call => <Call key={call.callUuid} {...call} />)}
        </div>
        <PaginationAndFilter
            displayRefresh={false}
            isLoading={isLoading || isRefetching}
            isError={isError}
            refreshFn={refetch}
            goPrevious={goPrevious}
            goNext={goNext}
            hasNext={dataToRender.hasNext}
            hasPrevious={dataToRender.hasPrevious}/>
    </div>;
};

export default CallsContainer;