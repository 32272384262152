import React from "react";
import {useQuery} from "@tanstack/react-query";
import {getCallDetails} from "../api/calls";
import Maps from "./Maps";

const Tabs = ({isOpen, color = "slate", callUuid}) => {
    const {isError, data: response} = useQuery({
        queryKey: ["get-call-detail-" + callUuid, {callUuid}],
        queryFn: getCallDetails,
        enabled: isOpen,
        refetchInterval: 10000, // refetch every 10 sec,
        refetchIntervalInBackground: true
    });

    if (!isOpen || !response || !response.length) return <></>;

    if (isError) {
        return <>
            <svg className="h-5 w-5 mr-1 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span className="text-lg text-red-400">There was some problem while loading the data, please try refreshing the page.</span>
        </>;
    }

    response[0].eml.forEach(callPushData => {
        let payload = callPushData.payload;
        const hasResolvedLocations = !!(payload && payload.length && payload[0].rankedLocations.length);
        // sort by confidence
        if (hasResolvedLocations) {
            payload[0].rankedLocations.sort((l1, l2) => l1.confidence > l2.confidence ? -1 : 1);
        }
    });

    const mostRecentCallPush = response[0].eml && response[0].eml.length ? response[0].eml[0] : {payload: [{rankedLocations: [{}]}]};
    const locations = response[0].eml && response[0].eml.length
        ? response[0].eml
            .filter(e => e.payload[0].gpsEstimated)
            .map(e => ({
                lat: e.payload[0].gpsLatitude,
                lng: e.payload[0].gpsLongitude,
                floor: e.payload[0].rankedLocations[0].dispatchableAddress.floor
            }))
        : [];
    const reportedGps = response[0].eml && response[0].eml.length
        ? response[0].eml.filter(e => {
            const {gpsReportedLatitude, gpsReportedLongitude} = e.payload[0];
            return gpsReportedLatitude && gpsReportedLongitude && gpsReportedLatitude !== "0" && gpsReportedLongitude !== "0";
        }).map(e => ({
            lat: e.payload[0].gpsReportedLatitude,
            lng: e.payload[0].gpsReportedLongitude,
            floor: e.payload[0]?.rankedLocations[0]?.dispatchableAddress?.floor
        }))
        : [];
    const aps = response[0].eml && response[0].eml.length
        ? response[0].eml
            .filter(e => e.payload[0]?.rankedLocations[0]?.tagGpsLatitude
                && e.payload[0]?.rankedLocations[0]?.tagGpsLongitude
                && e.payload[0]?.rankedLocations[0]?.dispatchableAddress
                && e.payload[0]?.rankedLocations[0]?.dispatchableAddress.floor)
            .map(e => {
                const loc = e.payload[0].rankedLocations[0];
                return {
                    bssid: loc.tagUsed,
                    ssid: loc.ssid,
                    lat: loc.tagGpsLatitude,
                    lng: loc.tagGpsLongitude,
                    floor: loc.dispatchableAddress.floor
                };
            })
        : [];
    return (
        <>
            <div className="relative flex flex-col w-full rounded">
                <Maps
                    topEmlLocation={mostRecentCallPush.payload[0].rankedLocations[0]}
                    locations={locations.length ? locations : aps}
                    reportedGps={reportedGps}
                    aps={aps}
                    callUuid={callUuid}
                    history={response[0].eml}
                />
            </div>
        </>
    );
};

export default Tabs;