import {useState} from "react";
import Tabs from "./Tabs";

function getAddress(dispatchableAddress) {
    const {borough = "", stateOrProvince = "", country = ""} = dispatchableAddress;
    let address = "";
    if (borough) {
        address += borough + ", ";
    }
    if (stateOrProvince) {
        address += stateOrProvince + ", ";
    }
    if (country) {
        address += country;
    }

    address = address || "No Resolved Location";
    return address;
}

const Call = ({phoneNumber, sourceIdentifier1, created, callUuid, eml}) => {
    const [detailOpen, setDetailOpen] = useState(false);
    const parsedDate = new Date(Date.parse(created));
    const toggleDetail = () => {
        setDetailOpen(!detailOpen);
        return false;
    }
    const phone = phoneNumber || sourceIdentifier1 || "Not Available";

    const emlLocations = eml && eml.length && eml[0].payload && eml[0].payload.length ? eml[0].payload[0].rankedLocations : [];
    emlLocations.sort((l1, l2) => l1.confidence > l2.confidence ? -1 : 1);
    const topEmlLocation = emlLocations.length ? emlLocations[0] : {dispatchableAddress: {}};
    const {dispatchableAddress = {}} = topEmlLocation;

    return <div className="flex flex-col p-8 sm:px-6 bg-slate-200 rounded-md">
        <div className="flex flex-row cursor-pointer" onClick={toggleDetail}>
            <div className="mr-4 my-auto">
                {!detailOpen && <svg fill="#4798f5" width="40" height="40" viewBox="0 0 32 32"
                                     xmlns="http://www.w3.org/2000/svg" stroke="#4798f5">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                    <g id="SVGRepo_iconCarrier">
                        <path d="M24 11.305l-7.997 11.39L8 11.305z"/>
                    </g>
                </svg>}
                {detailOpen && <svg fill="#4798f5" width="40" height="40" viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg" stroke="#4798f5"
                                    transform="rotate(0)matrix(1, 0, 0, -1, 0, 0)">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                    <g id="SVGRepo_iconCarrier">
                        <path d="M24 11.305l-7.997 11.39L8 11.305z"/>
                    </g>
                </svg>}
            </div>
            <div className="flex flex-row w-full">
                {PhoneIcon}
                <div className="flex flex-col w-full">
                    <div className="flex flex-row mb-2">
                        <span className="ml-2 text-xl font-medium text-slate-600">{phone}</span>
                        <div className="ml-auto flex flex-row">
                        {TimeIcon}
                            <span
                                className="ml-2 mb-1 font-medium text-slate-600">
                        {parsedDate.toDateString() + " " + parsedDate.toLocaleTimeString()}
                    </span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <span className="ml-3 font-medium text-lg text-blue-800">
                        {getAddress(dispatchableAddress)}
                        </span>
                        <span className="ml-auto text-sm mr-1 pt-2 font-medium leading-5 text-slate-500"></span>
                        <span className="pt-2 font-medium leading-5 text-sm text-slate-500">{callUuid}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex bg-slate-200 shadow-inner w-full">
            <div className={"w-full" + (!detailOpen ? " hidden " : "")}>
                <Tabs callUuid={callUuid} isOpen={detailOpen} />
            </div>
        </div>
    </div>;
};

export default Call;

const TimeIcon = <svg className="h-4 w-4 mt-1 ml-3 text-slate-600" width="24" height="24" viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <circle cx="12" cy="12" r="9"/>
    <polyline points="12 7 12 12 15 15"/>
</svg>;
const PhoneIcon = <svg className="mt-3 h-10 w-10 text-slate-500" width="24" height="24" viewBox="0 0 24 24"
                       strokeWidth="2"
                       stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path
        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"/>
</svg>;